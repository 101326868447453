<template  >
    <v-layout fill-height column ref="ref_top">
        <!-- //LOADING -->
        <v-card  v-if="!PAGE_LOAD" 
            :height="'100%'" >
            <mbs-page-spinner 
                height="500"/> 
        </v-card>

        <!-- //SHOW-PAGE -->
        <div v-else class=" pt-3">  
            <v-container grid-list-xs>
                <v-stepper v-model="step" vertical tile > 
                    <v-layout row wrap align-center class="px-5">
                        <h2 class="headline pt-4 pl-2  ">Register new User</h2> 
                        <v-spacer></v-spacer>
                        <!-- <v-btn 
                            class="mt-5" rounded outlined
                            to="/account/manage-users" 
                            color="success"><v-icon>mdi-account</v-icon> Manage Users</v-btn>  -->
                    </v-layout>
                    <!-- signup with phone -->
                    <div >
                        <!-- Step 1 signup with Phone-->
                        <div ref="ref_step1">
                            <v-stepper-step 
                                :complete="step > 1" 
                                step="1">
                                Phone number register
                            </v-stepper-step>
                            <v-stepper-content step="1"  >
                                <v-card
                                    color="grey  lighten-2 primary--text " 
                                    class="mb-5 pa-2">
                                    <h2 class="font-weight-light">PHONE NUMBER</h2>
                                    <v-card-text>
                                        <v-layout row wrap >
                                            <v-flex xs12 md4 >
                                                <v-layout class="mr-1"> 
                                                    <v-autocomplete
                                                        v-model="select.country"
                                                        :items="countryNames" 
                                                        filled
                                                        background-color="white"
                                                        label="Country" >
                                                        <template 
                                                            slot="selection"
                                                            slot-scope="data" >
                                                            <v-chip 
                                                                :input-value="data.selected"
                                                                :disabled="data.disabled"
                                                                :key="JSON.stringify(data.item)"
                                                                class="v-chip--select-multi"
                                                                
                                                                @input="data.parent.selectItem(data.item)" >
                                                                <v-avatar v-if="select.country!==''" 
                                                                    class="mr-2" >
                                                                        <span 
                                                                            style="font-size:30px"   >
                                                                            <flag :iso="select.country.split(':')[0]" />   
                                                                        </span> 
                                                                </v-avatar>

                                                                {{ data.item.split(': ')[1] }}
                                                            </v-chip> 
                                                        </template>
                                                    </v-autocomplete>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs12 md8>
                                                <v-text-field
                                                    filled
                                                    background-color="white"
                                                    id="inputPhone"  
                                                    v-model="inputPhone"
                                                    append-icon="phone" 
                                                    label="Phone Number"></v-text-field> 
                                            </v-flex> 
                                        </v-layout>
                                    
                                    </v-card-text>
                                    <small >Select country and enter your phone number to continue.</small>
                                </v-card>  
                                <v-btn  
                                    @click="CHECK_USER()"
                                    :loading="load"
                                    :disabled="!GET_PHONE_NUMBER(true).valid"
                                    color="primary" >Continue
                                    <v-icon  >chevron_right</v-icon>
                                </v-btn> 
                            </v-stepper-content> 
                        </div>

                        <!-- Step 2 add user info --> 
                        <div ref="ref_step2">
                            <v-stepper-step 
                                :complete="step > 2"
                                step="2">User information
                            </v-stepper-step>
                            <v-stepper-content step="2" >
                                <form id="userInfoForm" ref="ref_userInfoForm" @submit.prevent="CREATE_USER()">
                                    <v-card
                                        color="grey  lighten-2 primary--text " 
                                        class="mb-5 pa-3"
                                        >
                                        <v-card-title class="mb-5">
                                            <h2 class="font-weight-light">INFORMATION</h2>
                                            <v-spacer></v-spacer>
                                            <v-btn v-if="!input.nation_id" color="primary"   tile @click="dialog_scan_id = true" >
                                                <v-icon class="mr-1">mdi-qrcode-scan </v-icon>
                                                Scan ID
                                            </v-btn>
                                        </v-card-title>
                                        <v-card-text > 
                                            <v-subheader>User Information</v-subheader>
                                            <v-row>
                                                <v-col 
                                                    sm="6" cols="12">
                                                    <v-text-field 
                                                        filled 
                                                        background-color="white"
                                                        v-model="input.names"
                                                        prepend-inner-icon="person"
                                                        type="text"
                                                        :mask="mask.name"
                                                        required 
                                                        label="Names"/>  
                                                </v-col> 
                                                <v-col 
                                                    sm="6"
                                                    cols="12">
                                                    <v-text-field 
                                                        filled 
                                                        background-color="white"
                                                        v-model="input.surname"
                                                        prepend-inner-icon="person"
                                                        type="text"
                                                        :mask="mask.name"
                                                        required 
                                                        label="Surname"/>  
                                                </v-col> 
                                                <v-col
                                                    sm="6"
                                                    cols="12">
                                                    <v-select  
                                                        filled 
                                                        background-color="white"
                                                        :items="genderType"
                                                        v-model="input.gender"
                                                        label="Gender" 
                                                        hint="Gender" required
                                                        prepend-inner-icon="accessibility" /> 
                                                    
                                                </v-col> 
                                                <v-col
                                                    sm="6"
                                                    cols="12"> 
                                                    <v-menu  
                                                        v-model="menu_date_picker" 
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto" >
                                                        <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="input.date_of_birth" 
                                                            label="Date of Birth"  
                                                            background-color="white" 
                                                            readonly filled required
                                                            type="date"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="input.date_of_birth"  
                                                            @input="menu_date_picker = false"
                                                            ></v-date-picker>
                                                    </v-menu> 
                                                </v-col>   
                                            </v-row>   
                                            <br>
                                            <v-subheader>User Location Address</v-subheader>
                                            <v-row>
                                                <v-col sm="12" cols="12"> 
                                                    <v-autocomplete
                                                        v-model="select.country2"
                                                        :items="countryNames2" 
                                                        filled height="60"
                                                        background-color="white"
                                                        label="Select your Home Country" >
                                                        <template 
                                                            slot="selection"
                                                            slot-scope="data" >
                                                            <v-chip 
                                                                :input-value="data.selected"
                                                                :disabled="data.disabled"
                                                                :key="JSON.stringify(data.item)"
                                                                class="v-chip--select-multi"
                                                                clearable
                                                                @input="data.parent.selectItem(data.item)" >
                                                                <v-avatar v-if="select.country2!==''" 
                                                                    class="mr-2" >
                                                                        <span 
                                                                            style="font-size:30px"   >
                                                                            <flag :iso="select.country2.split(':')[0]" />   
                                                                        </span> 
                                                                </v-avatar>

                                                                {{ data.item.split(': ')[1] }}
                                                            </v-chip> 
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col sm="6" cols="12" v-if="select.country2 =='MW: Malawi'" >
                                                    <v-autocomplete
                                                        filled  background-color="white"
                                                        v-model="input.location_district_code" 
                                                        :items="districts"
                                                        item-text="name"
                                                        item-value="code"
                                                        :mask="mask.name"
                                                        required 
                                                        label="District"/> 
                                                </v-col>
                                                <v-col sm="6" cols="12" v-if="select.country2 =='MW: Malawi'" >
                                                    <v-text-field 
                                                        filled  background-color="white"
                                                        :mask="mask.name"
                                                        v-model="input.location_area"  
                                                        type="text"
                                                        required 
                                                        label="Area"/>   
                                                </v-col> 
                                                <v-col sm="12" cols="12"  >
                                                    <v-textarea background-color="white"
                                                        v-model="input.location_address"
                                                        label="Address/ Street / House number"  
                                                        filled required
                                                        />  
                                                </v-col>  
                                            </v-row>

                                            <br>
                                            <v-subheader>User ID Information</v-subheader>
                                            <v-row>
                                                <v-col sm="6" cols="12"> 
                                                    <v-autocomplete
                                                        background-color="white"
                                                        v-model="input.id_type" 
                                                        filled   
                                                        :items="IdTypes"
                                                        :item-value="'value'"
                                                        :item-text="'name'"
                                                        label="ID Type" />
                                                </v-col>
                                                <v-col sm="6" cols="12">
                                                    <v-text-field 
                                                        filled   background-color="white"
                                                        v-model="input.id_number"  
                                                        type="text"
                                                          
                                                        label="ID Number"/>   
                                                </v-col>  
                                                <v-col sm="6" cols="12">
                                                    <v-menu  
                                                        v-model="menu_id_issued_date" 
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto" >
                                                        <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            background-color="white"
                                                            v-model="input.id_issued_date" 
                                                            label="Issued Date"  
                                                            readonly filled  
                                                            type="date"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="input.id_issued_date"  
                                                            @input="menu_id_issued_date = false"
                                                            ></v-date-picker>
                                                    </v-menu>
                                                    
                                                </v-col>  
                                                <v-col sm="6" cols="12">
                                                    <v-menu  
                                                        v-model="menu_id_expiring_date" 
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto" >
                                                        <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            background-color="white"
                                                            v-model="input.id_expiring_date" 
                                                            label="Expiring Date"  
                                                            readonly filled  
                                                            type="date"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="input.id_expiring_date"  
                                                            @input="menu_id_expiring_date = false"
                                                            ></v-date-picker>
                                                    </v-menu> 
                                                </v-col>   
                                            </v-row> 

                                            <v-col>
                                                <div>To <strong>"Continue"</strong>, you must agree to the <v-btn color="c5" text to="/terms">Terms</v-btn> and <v-btn color="c5" text to="policy">Privacy Policy</v-btn></div>
                                                <v-checkbox
                                                    v-model="input.agree"
                                                    :rules="[v => !!v || 'You must agree to continue!']"
                                                    label="Do you agree?"
                                                    required
                                                ></v-checkbox>
                                            </v-col>
                                        </v-card-text> 
                                    </v-card>
                                    <v-btn 
                                        @click.native="NEXT_STEP(1,1000)">
                                        <v-icon>chevron_left</v-icon>
                                        Back
                                    </v-btn>
                                    <!-- :disabled="!GET_PHONE_NUMBER_NEXT_OF_KIN(true).valid" -->
                                    <v-btn 
                                        :loading="load"
                                        
                                        type="submit"
                                        color="primary">
                                        Continue
                                        <v-icon >chevron_right</v-icon>
                                    </v-btn> 
                                    <v-btn text @click="CANCEL_SIGNING()">Cancel</v-btn>
                                </form>   
                            </v-stepper-content>  
                        </div>

                        <!-- Step 3 confirm number -->
                        <div ref="ref_step3">
                            <v-stepper-step 
                                :complete="step > 3" 
                                step="3">
                                Verify user's phone number
                            </v-stepper-step>
                            <v-stepper-content step="3" >
                                <v-card
                                    color="grey  lighten-2 primary--text " 
                                    class="mb-5 pa-2">
                                    <v-card-title > 
                                        <h2 class="font-weight-light">VERIFY USER'S PHONE NUMBER</h2>
                                        <v-spacer></v-spacer> 
                                    </v-card-title>
                                    <v-card-text> 
                                        <v-card>
                                            <v-layout align-center class="pa-4 ">
                                                <div class="not-f7 font-weight-bold">{{PHONE_NUMBER?PHONE_NUMBER.international:".."}}</div>
                                            </v-layout> 
                                        </v-card>
                                    </v-card-text>  
                                    <small>Click the Verify Button bellow to send verification code sms to the users phone number.</small>
                                </v-card> 
                                <v-btn 
                                    id="bth_verify_number"
                                    :disabled="!(PHONE_NUMBER?PHONE_NUMBER.isValid:false)"
                                    color="primary" 
                                    ref="bthVerifyNumber"
                                    :loading="load" >Verify
                                    <v-icon class="ml-2" >message</v-icon>
                                </v-btn>
                                <v-btn text @click="CANCEL_NUMBER_VERIFICATION()">Later</v-btn>
                            </v-stepper-content>  
                        </div>
                        <!-- Step 4 confirm number -->
                        <div  ref="ref_step4">
                            <v-stepper-step 
                                :complete="step > 4" 
                                step="4">
                                Confirm user's phone number
                            </v-stepper-step>
                            <v-stepper-content step="4">
                                <form id="code" @submit.prevent="CONFIRM_PHONE_NUMBER(input.phoneConfirm)">
                                    <v-card
                                        color="grey  lighten-2 primary--text " 
                                        class="mb-5 pa-2">
                                        <v-card-title > 
                                            <h2 class="font-weight-light">CONFIRM YOUR PHONE NUMBER</h2>
                                            <v-spacer></v-spacer>
                                            <v-btn @click="REVERIFY_NUMBER()"
                                                small=""
                                                dark="" 
                                                class="blue" >Resend 
                                                <v-icon >refresh</v-icon>
                                            </v-btn>
                                        </v-card-title>
                                        <v-card-text> 
                                            <v-layout align-center>
                                                <v-text-field 
                                                    filled
                                                    background-color="white"
                                                    :mask="mask.code"
                                                    v-model="input.phoneConfirm"
                                                    append-icon="announcement"
                                                    type="number"
                                                    label="Enter confirmation code"></v-text-field>
                                            </v-layout>
                                        </v-card-text>  
                                        <small>Please, Enter the confirmation code sent to your number.You can click Resend button to resend the code, if it's taking too long.</small>
                                    </v-card>
                                    <v-btn 
                                        @click.native="step = 2">
                                        <v-icon  >chevron_left</v-icon>
                                        Back
                                    </v-btn>
                                    <v-btn 
                                        :disabled="input.phoneConfirm.length<6"
                                        color="primary" 
                                        type="submit"
                                        :loading="load" >Continue
                                        <v-icon  >chevron_right</v-icon>
                                    </v-btn>
                                    <v-btn text @click="CANCEL_NUMBER_VERIFICATION()">Cancel</v-btn>
                                </form>
                            </v-stepper-content>  
                        </div>
                    </div>  
                </v-stepper>  
            </v-container> 
        </div> 

        <!-- Scan -->
        <v-dialog v-model="dialog_scan_id" persistent  max-width="800px" > 
            <v-card flat >
                <v-card-title class="primary white--text" dark>
                    <span class="headline">SCAN NATION ID</span>
                    <v-spacer></v-spacer>
                    <v-btn class="ml-2" dark @click="dialog_scan_id=false" icon=""><v-icon>close</v-icon></v-btn>
                </v-card-title> 
                <div v-if="!SCANNED_NATION_ID">
                    <v-card v-if="!SCANNING" >
                        <v-layout column  justify-center align-center fill-height class="py-15">
                            <div><v-icon size="80">mdi-qrcode-scan</v-icon></div>
                            <v-btn color="primary" tile outlined class="mt-5">Scan ID</v-btn>
                        </v-layout>
                    </v-card>
                    <v-card v-else >
                        <v-layout column  justify-center align-center fill-height class="py-15">
                            <div><v-icon size="80">mdi-magnify-scan </v-icon></div> 
                            <v-col cols="4" sm="2" md="2">
                                <v-progress-linear
                                    color="primary darken-2 "
                                    indeterminate
                                    rounded
                                    height="10" striped
                                ></v-progress-linear>  
                            </v-col>
                        </v-layout>
                    </v-card> 
                </div>
                <div v-else>
                    <v-card-text>
                        <v-list>
                            <v-list-item> 
                                <v-list-item-action>
                                    <v-icon>mdi-card-account-details</v-icon>
                                </v-list-item-action>
                                <v-list-item-subtitle>
                                    ID Type
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    {{SCANNED_NATION_ID.id_type}}
                                </v-list-item-title> 
                            </v-list-item> 
                            <v-list-item> 
                                <v-list-item-action>
                                    <v-icon>mdi-card-account-details-star</v-icon>
                                </v-list-item-action>
                                <v-list-item-subtitle>
                                   ID Number
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    {{SCANNED_NATION_ID.id_number}}
                                </v-list-item-title>
                            </v-list-item>  

                            <v-list-item> 
                                <v-list-item-action>
                                    <v-icon>mdi-account-box</v-icon>
                                </v-list-item-action>
                                <v-list-item-subtitle>
                                    Surname
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    {{SCANNED_NATION_ID.id_surname}}
                                </v-list-item-title> 
                            </v-list-item>  

                             <v-list-item> 
                                <v-list-item-action>
                                    <v-icon>mdi-account-box-multiple </v-icon>
                                </v-list-item-action>
                                <v-list-item-subtitle>
                                    Names
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    {{SCANNED_NATION_ID.id_names}}
                                </v-list-item-title>
                            </v-list-item>  

                            <v-list-item> 
                                <v-list-item-action>
                                    <v-icon>mdi-cake-variant</v-icon>
                                </v-list-item-action>
                                <v-list-item-subtitle>
                                    Date of Birth
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    {{SCANNED_NATION_ID.id_date_of_birth}}
                                </v-list-item-title>
                            </v-list-item>  

                            <v-list-item> 
                                <v-list-item-action>
                                    <v-icon>accessibility</v-icon>
                                </v-list-item-action>
                                <v-list-item-subtitle>
                                    Gender
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    {{SCANNED_NATION_ID.id_gender}}
                                </v-list-item-title>
                            </v-list-item>  

                            
                        </v-list>  
                        <v-layout class="mr-5 mb-2">
                            <v-spacer></v-spacer>
                            <v-btn @click="useNationIdToRegister()" color="primary" tile outlined class="mt-5">Use to Register</v-btn>
                        </v-layout>
                    </v-card-text> 
                </div>
            </v-card>
        </v-dialog>
    </v-layout> 
</template>
 
<script>
    //firebase
    import firebase  from 'firebase/app'
    import 'firebase/auth' 
    import DATA from '../../../plugins/DATA'
    import DB from '../../../plugins/DB'

    let PAGE_NAME = 'REGISTER-USER' 
    export default {
        name:PAGE_NAME,
        props:['id'], 
        data () {
            return {   
                PAGE_LOAD:false,    
                ALREADY_REGISTERED_US:null,
                ALREADY_REGISTERED_UD:null,
                USER_ALREADY_REGISTERED:false, 
                NEW_USER:null,
                VERIFICATION_ID:null,
                SCANNED_NATION_ID:null,
                SCANNED_CODE:null,
                SCANNING:false,
                DATA:DATA,
                
                
                load:false,
                step:1,  
                //---------selectors---------
                select:{
                    signOption:'',
                    accountType:'',
                    businessType:'',
                    country:'',
                    countryNextOfKin:'',
                }, 
                input:{
                    names:'',
                    surname:'',
                    gender:'',
                    date_of_birth:'',
                    phoneConfirm:'',
                    email:'',
                    password:'',
                    passwordConfirm:'',
        
                    district_code:'',
                    location_area:'',
                    location_street:'',
                    agree:false
                },
                inputPhone:'',
                inputPhoneNextOfKin:'',
                menu_date_picker:false, 
                menu_id_issued_date:false,
                menu_id_expiring_date:false,
                dialog_scan_id:false,
                //-----------Form rules----------
                rules: { 
                    it_os:[
                        val => (val || '').length > 0 || 'You must select OS'
                    ],
                    it_brand:'',
                    it_name:'',
                    it_model:'',  
                }, 
                //-----------mask---------------
                mask: {
                    price:'########',
                    year:'####',
                    code:'######',
                    phone:'phone',
                    name:'Nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn',
                    date:'date',
                    yEmail:'nnnnnnnnnnnnnnnnnnnn'
                }, 
                genderType:['Male','Female'],
                relationship:['Family Member','Friend'], 
                signOption:[
                    "Use Phone Number","Use Gmail Account"
                ]

                
            }
        },
        created(){ 
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
                this.MBS.events.$on('ON_QR_SCAN', this.ON_QR_SCAN);
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                })
            }
        },
        mounted(){ 
            try {
                console.log(this.id,'id.....');
                //set header
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar',  
                    height:80 
                })
                this.MBS.actions.subToolbar({
                    show:true,
                    height:70,
                    flat:false,
                    color:'primary',
                    textColor:'white', 
                    side:'right'
                }) 
                this.FEEDBACKS()    
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                })
            }
        },
        computed: {
            //common 
            vs(){
                return this.$vuetify.breakpoint
            }, 
            us(){
                let returnUser = this.$store.getters.getUser
                return returnUser
            }, 
            ud(){
                let returnUser = this.$store.getters.getUserDetails
                return returnUser
            },  
            loading(){ 
                const loading = this.$store.getters.getLoading
                return loading
            },
            processes(){ 
                const process = this.$store.getters.getProcesses
                return process
            }, 
            responses(){ 
                const re = this.$store.getters.getResponses
                return re
            },

            ///////// 
            IdTypes(){ 
                let items = this.$store.getters.getIdTypes
                return items
            },
            districts(){ 
                const re = this.$store.getters.getDistricts
                return re
            },
            locations(){
                let districts =[]
                let all = this.$store.getters.getCustomCountries
                if(!all){return null}
                all.forEach(country => {
                    let allD = country.districts
                    allD.forEach(dis => {
                        districts.push(dis.name+' ('+dis.region+' Region)')
                    })
                })
                return districts   
            },
            countryNames(){
                let names = []
                let countries = this.$store.getters.getCountries 
                for (const key in countries) {
                    const cn = countries[key]
                    if (cn.alpha2!== undefined) {
                        names.push(cn.alpha2+': '+cn.name+' ('+cn.countryCallingCodes+')') 
                    } 
                } 
                //names=['Mw: Malawi (+265)']
                return names
            }, 
            countryNames2(){
                let names = []
                let countries = this.$store.getters.getCountries 
                for (const key in countries) {
                    const cn = countries[key]
                    if (cn.alpha2!== undefined) {
                        names.push(cn.alpha2+': '+cn.name) 
                    } 
                } 
                //names=['Mw: Malawi (+265)']
                return names
            }, 
            iLocation(){ 
                const i = this.$store.getters.getIpLocation
                return i
            }, 
            PHONE_NUMBER(){
                try { 
                    let country = this.select.country
                    let phone = this.inputPhone
                    let region_code = null
                    if (!phone) {return null}
                    if (country) {
                        region_code = country.split(':')[0]
                    }
                    let os_phone = new this.MBS.phone(phone,region_code)
                    let isValid = os_phone.isValid()
                    let number = os_phone.getNumber()
                    let international = os_phone.getNumber( 'international' )
                    let national = os_phone.getNumber( 'national' )
                    let countryCode = os_phone.getCountryCode( );   
                    return {
                        os:os_phone,
                        isValid:isValid,
                        number:number,
                        international:international,
                        national:national,
                        countryCode:countryCode
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PHONE_NUMBER',
                        page:PAGE_NAME, 
                    })
                }  
            },
            GET_PHONE_NUMBER(){
                try {
                    let us = this.us  
                    let input_country = ''+this.select.country
                    let input_phone = ''+this.inputPhone 
                    let countryNames = this.countryNames 
                    let PHONE = this.MBS.phone 
                    return all =>{
                        let current_phone = ''+input_phone 
                        let ip_phone = null 
                        let ip_countryPhone = null 

                        if (input_phone) {
                            input_phone = input_phone.replace(/[^0-9+]/g,'')
                        }
                        if (input_country) {
                            input_country = input_country.replace(/[^0-9]/g,'')
                        } 

                        if (input_phone) { 
                            ip_phone = PHONE(input_phone)
                        }
                        if (input_country) { 
                            ip_countryPhone = PHONE('+'+(input_country)+''+input_phone)
                        } 

                        if (ip_phone) {
                            let possible = ip_phone.g?ip_phone.g.possible:null
                            if (possible) {
                                current_phone = ip_phone.g

                                if (countryNames) { 
                                    let regionCode = current_phone.regionCode
                                    let countryName = countryNames.find(item=>{
                                        return this.MBS.actions.TEXT_UP(item).includes(this.MBS.actions.TEXT_UP(regionCode+':'))
                                    })
                                    if (countryName != this.select.country) {
                                        this.select.country = countryName
                                    } 
                                }
                            }
                        }
                        if (ip_countryPhone) {
                            let possible = ip_countryPhone.g?ip_countryPhone.g.possible:null
                            if (possible) {
                                current_phone = ip_countryPhone.g
                            }
                        } 

                        if (current_phone.valid) {
                            if (this.inputPhone != current_phone.number.international ) { 
                                this.inputPhone = current_phone.number.international
                            }
                        } 

                        let returnData = {
                            ...current_phone,
                            ...current_phone.number,
                            phone:current_phone.number?current_phone.number.e164:'',
                            phone_number:current_phone.number?current_phone.number.e164:'',
                        }
                        // console.log(input_phone,'input_phone.........');
                        // console.log(input_country,'input_country.........');  
                        // console.log(ip_countryPhone,'ip_countryPhone.........');
                        // console.log(ip_phone,'ip_phone.........');  
                        // console.log(current_phone,'current_phone.........'); 
                        // console.log(returnData,'returnData.........'); 

                        if (all) {
                            return returnData
                        } else {
                            return current_phone.number?current_phone.number.e164:''
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_PHONE_NUMBER',
                        page:PAGE_NAME, 
                    })
                }  
            },
            GET_PHONE_NUMBER_NEXT_OF_KIN(){
                try {
                    let us = this.us 
                    let input_country = ''+this.select.countryNextOfKin 
                    let input_phone = ''+this.inputPhoneNextOfKin 
                    let countryNames = this.countryNames 
                    let PHONE = this.MBS.phone 
                    return all =>{
                        let current_phone = ''+input_phone 
                        let ip_phone = null 
                        let ip_countryPhone = null 

                        if (input_phone) {
                            input_phone = input_phone.replace(/[^0-9+]/g,'')
                        }if (input_country) {
                            input_country = input_country.replace(/[^0-9]/g,'')
                        } 

                        if (input_phone) { 
                            ip_phone = PHONE(input_phone)
                        }if (input_country) { 
                            ip_countryPhone = PHONE('+'+(input_country)+''+input_phone)
                        } 

                        if (ip_phone) {
                            let possible = ip_phone.a?ip_phone.a.possible:null
                            if (possible) {
                                current_phone = ip_phone.a

                                if (countryNames) { 
                                    let regionCode = current_phone.regionCode
                                    let countryName = countryNames.find(item=>{
                                        return this.MBS.actions.TEXT_UP(item).includes(this.MBS.actions.TEXT_UP(regionCode+':'))
                                    })
                                    if (countryName != this.select.countryNextOfKin) {
                                        this.select.countryNextOfKin = countryName
                                    } 
                                }
                            }
                        }
                        if (ip_countryPhone) {
                            let possible = ip_countryPhone.a?ip_countryPhone.a.possible:null
                            if (possible) {
                                current_phone = ip_countryPhone.a
                            }
                        }else{

                        }

                        if (current_phone.valid) {
                            if (this.inputPhoneNextOfKin != current_phone.number.international ) {
                                this.inputPhoneNextOfKin = current_phone.number.international
                            }
                        }  

                        if (all) {
                            return {
                                ...current_phone,
                                phone:current_phone.number?current_phone.number.e164:''
                            }
                        } else {
                            return current_phone.number?current_phone.number.e164:''
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'GET_PHONE_NUMBER_NEXT_OF_KIN',
                        page:PAGE_NAME, 
                    })
                }  
            },
            
        }, 
        methods: { 
            //RECAPTACH
            createRecaptach(){
                try {
                    const self = this; 
                    setTimeout(()=>{ 
                        if (!window.recaptchaVerifier) {  
                            // Start Firebase invisible reCAPTCHA verifier
                            firebase.auth().useDeviceLanguage();
                            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('bth_verify_number', {
                                size: 'invisible',
                                callback: (res) => {
                                    // reCAPTCHA solved, allow signInWithPhone_number. 
                                    self. VERIFY_PHONE_NUMBER(); 
                                },
                                'expired-callback': function(res) {  
                                    self.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:"TIME OUT",
                                        text:"To continue Registering, Click Continue Button or Cancel Button to cancel.",
                                        btnYes:"Continue",
                                        btnNoTxt:"Cancel",
                                        action:{
                                            data:PAGE_NAME+"=REGISTER-TIMEOUT"
                                        }
                                    }) 
                                }
                            });
                            self.setRecaptach()  
                        } else {
                            self.reSetRecaptach() 
                        } 
                    },50) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'createRecaptach',
                        page:PAGE_NAME, 
                    })
                }
            },
            setRecaptach(){
                try {
                    window.recaptchaVerifier.render().then((widgetId) => {
                        window.recaptchaWidgetId = widgetId;
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'setRecaptach',
                        page:PAGE_NAME, 
                    })
                }
            },
            reSetRecaptach(){
                try {
                    // window.recaptchaVerifier.render().then((widgetId)=> {
                    //     grecaptcha.reset(widgetId)
                    // })
                    window.recaptchaVerifier = null
                    this.createRecaptach() 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'reSetRecaptach',
                        page:PAGE_NAME, 
                    })
                }
            }, 
            useNationIdToRegister(){
                try { 
                    if (this.SCANNED_NATION_ID) {
                        this.input.surname = this.SCANNED_NATION_ID.id_surname
                        this.input.names = this.SCANNED_NATION_ID.id_names
                        this.input.gender = this.SCANNED_NATION_ID.id_gender
                        this.input.date_of_birth = this.SCANNED_NATION_ID.id_date_of_birth
                        this.input.id_number = this.SCANNED_NATION_ID.id_number
                        this.input.id_type = this.SCANNED_NATION_ID.id_type
                        this.input.id_expiring_date = this.SCANNED_NATION_ID.id_expiring_date
                        this.input.id_qr_code = this.SCANNED_NATION_ID.id_qr_code
                        this.dialog_scan_id = false
                    }
                    this.FOCUS_STEP('top',100)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'useNationIdToRegister',
                        page:PAGE_NAME, 
                    })
                }
            }, 

            NEXT_STEP (step,time) {
                try {
                    this.load= true
                    setTimeout(()=>{
                        this.step=step
                        this.load=false
                    },time) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'NEXT_STEP',
                        page:PAGE_NAME, 
                    })
                } 
            }, 
            FOCUS_STEP (step=1,time=1000) {
                try { 
                    setTimeout(()=>{
                        let ref_step1 = this.$refs.ref_step1
                        let ref_step2 = this.$refs.ref_step2
                        let ref_step3 = this.$refs.ref_step3
                        let ref_step4 = this.$refs.ref_step4
                        let ref_top= this.$refs.ref_top
                        let ref = null
                        if (step == 1) {
                            ref = ref_step1
                        } if (step == 2) {
                            ref = ref_step2
                        }if (step == 3) {
                            ref = ref_step3
                        }if (step == 4) {
                            ref = ref_step4
                        } if (step == 'top') {
                            ref = ref_top
                        } 
                        if (ref) {
                            ref.scrollIntoView({behavior: 'smooth'});
                            console.log(ref,'ref');
                        }
                    },time) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'NEXT_STEP',
                        page:PAGE_NAME, 
                    })
                } 
            }, 
            async CHECK_USER(){
                try {  
                    let PHONE = this.GET_PHONE_NUMBER(true)
                    if (PHONE.valid) {  
                        this.load = true
                        try {
                            let phone_number = PHONE.phone 
                            let prams = {
                                phone_number:phone_number,
                                key:DATA.APP.key.apiKey
                            }
                            let res = await this.$http.post(DATA.APP.SERVER_LINK+'app/checkUser',prams)
                            let body = res?res.body:null
                            this.load = false
                            let user = body?body.user:null
                            let user_details = body?body.user_details:null
                            this.ALREADY_REGISTERED_US = user
                            this.ALREADY_REGISTERED_UD = user_details
                            if (user_details) {
                                this.USER_ALREADY_REGISTERED = true
                                let mbs_text = this.MBS.text.phone_number_already_registered(user_details.phone_number+" - "+user_details.surname)
                                this.MBS.actions.dialog({
                                    show:true,
                                    fixed:true,
                                    title:mbs_text.title,
                                    text:mbs_text.text,
                                    btnYes:mbs_text.btnYes,
                                })
                            }else if(user) {
                                this.USER_ALREADY_REGISTERED = true
                                console.log(user,'user..v....');  
                                this.load = true
                                let resDelete = await this.$http.post(DATA.APP.SERVER_LINK+'app/deleteUser',prams)
                                let body = resDelete?resDelete.body:null 
                                this.load = false
                                if (body.deleted) {
                                   this.CHECK_USER() 
                                }
                            } else {
                                this.USER_ALREADY_REGISTERED = false
                                this.NEXT_STEP(2,10)
                            }  
                        } catch (error) {
                            this.load = false
                            this.ALREADY_REGISTERED_US = null
                            this.ALREADY_REGISTERED_UD = null
                            this.USER_ALREADY_REGISTERED = false
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.error_0.title,
                                text:this.MBS.text.error_0.text,
                                btnYes:this.MBS.text.error_0.btnYes,
                            })
                            this.MBS.actions.error({
                                error:{
                                    error:error,
                                    phone_number:PHONE
                                },
                                from:'CHECK_USER',
                                page:PAGE_NAME, 
                            }) 
                        } 
                    } else {
                        this.MBS.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.error_0.title,
                            text:this.MBS.text.error_0.text,
                            btnYes:this.MBS.text.error_0.btnYes,
                        })
                        this.MBS.actions.error({
                            error:{
                                error:'Invalid Phone Number',
                                phone_number:PHONE
                            },
                            from:'CHECK_USER',
                            page:PAGE_NAME, 
                        })
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_USER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },   
            CREATE_USER(){
                try { 
                    let us  = this.us
                    let ud = this.ud
                    let uid = us?us.uid:null
                    let input = this.input 
                    let date = new Date   
                    let password = DATA.APP.DEFAULT_PASSWORD
                    let phone_number = this.PHONE_NUMBER?this.PHONE_NUMBER.number:null 
                    let email = phone_number+this.MBS.DATA.APP.EMAIL_SERVER

                    if (!input) {
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:this.MBS.text.error_0.title,
                            text:this.MBS.text.error_0.text,
                            btnYes:this.MBS.text.error_0.btnYes,
                        }) 
                        return
                    } 

                    //Create User Data
                    let detail ={ 
                        signup_status:'information', 
                        owner:DATA.APP.NAME,

                        phone_number:phone_number, 
                        names:input.names,
                        surname:input.surname,
                        gender:input.gender,
                        date_of_birth:input.date_of_birth ,

                        location_country:this.select.country2 ,
                        location_address:input.location_address ,
 
                        p_uid:this.MBS.crypt.encrypt(password),
                        email:email,
                        created_by:us.uid,
                        created_at:date.toISOString()
                    }; 
                    if (input.location_district_code) {
                        detail.location_district_code = input.location_district_code
                    }if (input.location_area) {
                        detail.location_area = input.location_area
                    }
                    
                    if (input.id_type) {
                        detail.id_type = input.id_type
                    }if (input.id_number) {
                        detail.id_number = input.id_number
                    }if (input.id_issued_date) {
                        detail.id_issued_date = input.id_issued_date
                    }if (input.id_expiring_date) {
                        detail.id_expiring_date = input.id_expiring_date
                    }

                    if (!detail.phone_number) {
                        let mbs_text = this.MBS.text.field_required("phone number")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                        }) 
                        return
                    } else if (!detail.date_of_birth) {
                        let mbs_text = this.MBS.text.field_required("date of birth")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                        }) 
                        return
                    } else if (!detail.location_country) {
                        let mbs_text = this.MBS.text.field_required("location country")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                        }) 
                        return
                    }  
                    let mbs_text = this.MBS.text.item_action('User',"Register")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true, 
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=REGISTER-USER",
                            detail:detail
                        }
                    })   
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_USER',
                        page:PAGE_NAME, 
                    })
                }
            },   
            REGISTER_USER(detail){
                try {  
                    let company_key = this.id
                    let us = this.us
                    let uid = us?us.uid:null
                    let self = this
                    let date = new Date  
                    self.load = true
                    self.NEW_USER = null  
                    if (!detail) {
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:self.MBS.text.error_0.title,
                            text:self.MBS.text.error_0.text,
                            btnYes:self.MBS.text.error_0.btnYes,
                        }) 
                        return
                    } 
 
                    //creating user 
                    self.MBS.actions.progressDialog({
                        show:true,
                        fixed:true,
                        title:'Creating user...'
                    })
                    let STEPS = 0
                    DB.addItems({
                        name:DATA.ITEMS.USER_LIVES.values,
                        data:{
                            key:DATA.APP.USER_TIMELINE.action_request,
                            date:date,
                            uid:uid
                        }
                    })
                    .then(result=>{
                        STEPS = 1
                        return firebase.auth().createUserWithEmailAndPassword(detail.email,self.MBS.crypt.decrypt(detail.p_uid))
                    })  
                    .then((userCredential) => { 
                        STEPS = 2
                        self.NEW_USER = userCredential.user;  
                        self.MBS.actions.progressDialog({
                            show:true,
                            fixed:true,
                            title:'Creating...'
                        })
                        return self.NEW_USER.updateProfile({ 
                            displayName: detail.surname+" "+ detail.names
                        })  
                    }) 
                    .then(()=> {  
                        STEPS = 3
                        self.MBS.actions.progressDialog({
                            show:true,
                            fixed:true,
                            title:'Registering  user...'
                        }) 
                        detail.uid = self.NEW_USER.uid 
                        console.log(detail,'detail..........'); 
                        return  firebase.database().ref('USERS/'+self.NEW_USER.uid).set(detail)
                    })  
                    .then((res)=> {   
                        if (company_key) {
                            let company_path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.company_users,true)
                            return  this.$store.dispatch("fi_update",{ 
                                action:DATA.ITEMS.PERMISSIONS.values,
                                path:company_path+self.NEW_USER.uid,
                                data:{
                                    uid: self.NEW_USER.uid
                                } 
                            })
                        } 
                        return  res
                    })  
                    .then((res)=> {   
                        if (company_key) { 
                            return  this.$store.dispatch("fi_update",{ 
                                action:DATA.ITEMS.PERMISSIONS.values,
                                path:"/USERS/"+self.NEW_USER.uid+"/companies/"+company_key,
                                data:{
                                    registered_by_company_code:company_key
                                }
                            })
                        } 
                        return  res
                    })    
                    .then(()=>{
                        //added  user details
                        STEPS = 4 
                        self.load=false
                        self.MBS.actions.progressDialog()
                        self.NEXT_STEP(3,100)
                        self.createRecaptach()
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:self.MBS.text.user_registered_verify_number(self.NEW_USER.displayName).title,
                            text:self.MBS.text.user_registered_verify_number(self.NEW_USER.displayName).text,
                            btnYes:self.MBS.text.user_registered_verify_number(self.NEW_USER.displayName).btnYes,
                        })   
                    }).catch(function(error) {  
                        self.load=false
                        self.MBS.actions.progressDialog()
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:"ERROR",
                            text:error.message,
                            btnYes:"OK"
                        })  
                        self.MBS.actions.error({
                            error:{
                                error:error,
                                STEPS:STEPS
                            },
                            from:'REGISTER_USER',
                            page:PAGE_NAME, 
                        })
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REGISTER_USER',
                        page:PAGE_NAME, 
                    })
                }
            },   
            VERIFY_PHONE_NUMBER(){
                try {
                    this.load = true
                    let self = this
                    let phoneNumber = this.PHONE_NUMBER.number;  
                    let appVerifier = window.recaptchaVerifier 
                    let provider = new firebase.auth.PhoneAuthProvider();

                    // Turn off phone auth app verification.
                    // firebase.auth().settings.appVerificationDisabledForTesting = true; 
                    self.MBS.actions.progressDialog({
                        show:true,
                        fixed:true,
                        title:'Sending verification sms...'
                    }) 
                    provider.verifyPhoneNumber(phoneNumber, appVerifier)
                    .then(function (verificationId) {
                        self.VERIFICATION_ID = verificationId 
                        self.load=false
                        self.MBS.actions.progressDialog()
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:self.MBS.text.verification_sent(phoneNumber).title,
                            text:self.MBS.text.verification_sent(phoneNumber).text,
                            btnYes:self.MBS.text.verification_sent(phoneNumber).btnYes,
                        })  
                        self.NEXT_STEP(4,1)
                    }) 
                    .catch((error) => { 
                        self.load=false
                        self.MBS.actions.progressDialog()
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:"ERROR",
                            text:error.message,
                            btnYes:"OK"
                        })  
                        self.MBS.actions.error({
                            error:error,
                            from:'VERIFY_PHONE_NUMBER',
                            page:PAGE_NAME, 
                        })
                    });
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VERIFY_PHONE_NUMBER',
                        page:PAGE_NAME, 
                    })
                }
            },  
            CONFIRM_PHONE_NUMBER(verificationCode){
                try {
                    this.load = true
                    let self = this 
                    let phoneNumber = this.PHONE_NUMBER.number;  
                    let verificationId = this.VERIFICATION_ID  
                    let STEPS = 0

                    self.MBS.actions.progressDialog({
                        show:true,
                        fixed:true,
                        title:'Checking code...'
                    }) 
                    let phoneCredential = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode)
                    self.NEW_USER.updatePhoneNumber(phoneCredential) 
                    .then(()=> {   
                        STEPS = 1
                        self.MBS.actions.progressDialog({
                            show:true,
                            fixed:true,
                            title:'Confirming code...'
                        }) 
                        let detail ={ 
                            phone_number_verified:true,  
                        }; 
                        return  firebase.database().ref('USERS/'+self.NEW_USER.uid).update(detail)
                    }) 
                    .then(() => {  
                        STEPS = 2
                        self.load=false
                        self.MBS.actions.progressDialog()  
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:self.MBS.text.phone_number_success_confirmed(phoneNumber).title,
                            text:self.MBS.text.phone_number_success_confirmed(phoneNumber).text,
                            btnYes:self.MBS.text.phone_number_success_confirmed(phoneNumber).btnYes,
                        })
                        self.SIGNING_BACK() 
                    }) 
                    .catch((error) => {  
                        self.load=false
                        self.MBS.actions.progressDialog()
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:"ERROR",
                            text:error.message,
                            btnYes:"OK"
                        })  
                        self.MBS.actions.error({
                            error:{
                                error:error,
                                STEPS:STEPS
                            },
                            from:'CONFIRM_PHONE_NUMBER',
                            page:PAGE_NAME, 
                        })
                    });
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_PHONE_NUMBER',
                        page:PAGE_NAME, 
                    })
                }
            },  
            REVERIFY_NUMBER(){
                try {
                    this.load = true
                    let number = this.us?this.us.phoneNumber:null
                    if (number) { 
                        this.reSetRecaptach()
                        this.NEXT_STEP(3)
                        // let phone = new this.MBS.phone(number)
                        // let b = phone?phone.b:null
                        // if(b.b){
                        //     this.inputPhone = b.b[2]
                        //     const phoneConfirmBtn = this.$refs.phoneConfirmBtn
                        //     setTimeout(() => {
                        //         // console.log(phoneConfirmBtn);

                        //         // phoneConfirmBtn.click(this.NEXT_STEP('number',10000)) 
                        //     },1000);  
                        // }

                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REVERIFY_NUMBER',
                        page:PAGE_NAME, 
                    })
                }
            },  
            CANCEL_SIGNING(){
                try { 
                    let number = this.PHONE_NUMBER?this.PHONE_NUMBER.number:'...'
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:this.MBS.text.cancel_registering(number).title,
                        text:this.MBS.text.cancel_registering(number).text,
                        btnYes:this.MBS.text.cancel_registering(number).btnYes,
                        btnNo:this.MBS.text.cancel_registering(number).btnNo,
                        action:{
                            code:PAGE_NAME+"=CANCEL-SIGNING"
                        }
                    })  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CANCEL_SIGNING',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            CANCEL_NUMBER_VERIFICATION(){
                try { 
                    let number = this.PHONE_NUMBER?this.PHONE_NUMBER.number:'...'
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:this.MBS.text.cancel_number_verification(number).title,
                        text:this.MBS.text.cancel_number_verification(number).text,
                        btnYes:this.MBS.text.cancel_number_verification(number).btnYes,
                        btnNo:this.MBS.text.cancel_number_verification(number).btnNo,
                        action:{
                            code:PAGE_NAME+"=CANCEL-NUMBER-VERIFICATION"
                        }
                    })  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CANCEL_NUMBER_VERIFICATION',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            async SIGNING_BACK(){
                try { 
                    let self = this
                    let user_details_data = await DB.getUserDetails() 
                    let  user_details = user_details_data.user_details 
                    if(!user_details) {
                        self.MBS.actions.progressDialog()
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:"ERROR",
                            text:'Something went wrong',
                            btnYes:"OK"
                        })  
                        self.MBS.actions.error({
                            error:error,
                            from:'SIGNING_BACK',
                            page:PAGE_NAME, 
                        })
                        self.MBS.actions.logout(true)
                        self.MBS.actions.go('/')
                        return null
                    } 
                    let p_uid = user_details.p_uid
                    console.log(p_uid);
                    let password = p_uid?this.MBS.crypt.decrypt(p_uid):null
                    let email = user_details.email 
                     
                    self.MBS.actions.progressDialog({
                        show:true,
                        fixed:true,
                        title:'Refreshing...'
                    }) 
                    DB.deleteItems({
                        name:DATA.ITEMS.USER_LIVES.values,
                        key:DATA.APP.USER_TIMELINE.action_request
                    })
                    .then(results=>{ 
                        return  firebase.auth().signInWithEmailAndPassword(email,password)
                    })
                    .then(res=>{ 
                        self.MBS.actions.go('/account')
                        setTimeout(() => {
                            self.MBS.actions.progressDialog()
                            self.MBS.actions.go('/account/register-user')
                        }, 1000); 
                    })
                    .catch(error=>{ 
                        self.MBS.actions.progressDialog()
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:"ERROR",
                            text:error.message,
                            btnYes:"OK"
                        })  
                        self.MBS.actions.error({
                            error:error,
                            from:'SIGNING_BACK',
                            page:PAGE_NAME, 
                        })
                        self.MBS.actions.logout(true)
                        self.MBS.actions.go('/')
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SIGNING_BACK',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            //----------------[MAIN FUNCTIONS]------------------//
            DIALOG_YES(action){ 
                try {
                    if (action) {   
                        if (action.code ===PAGE_NAME+"=CANCEL-SIGNING") {
                            if (action.YES) {
                                this.SIGNING_BACK()
                            } else {
                                
                            }
                        }
                        if (action.code ===PAGE_NAME+"=CANCEL-NUMBER-VERIFICATION") {
                            if (action.YES) {
                                this.SIGNING_BACK()
                            } else {
                                
                            }
                        }if (action.code ===PAGE_NAME+"=REGISTER-USER") {
                            if (action.YES) {
                                this.REGISTER_USER(action.detail)
                            } else {
                                
                            }
                        }
                        if (action.code ===PAGE_NAME+"=LOGIN") {
                            if (action.YES) { 
                                let password = action.password 
                                let password2 = this.ud.p_uid 
                                if (password===password2) {
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:this.MBS.text.login_successful.title,
                                        text:this.MBS.text.login_successful.text,
                                        btnYes:this.MBS.text.login_successful.btnYes, 
                                    })
                                    this.MBS.actions.go('/account/profile')
                                }else{ 
                                    setTimeout(() => {
                                        this.MBS.actions.dialogInput({
                                            show:true,
                                            fixed:true, 
                                            title:this.MBS.text.login_wrong_password.title,
                                            text:this.MBS.text.login_wrong_password.text,
                                            btnYes:this.MBS.text.login_wrong_password.btnYes,
                                            btnNo:this.MBS.text.login_wrong_password.btnNo,
                                            action:{
                                                code:PAGE_NAME+"=LOGIN", 
                                            },
                                            field:[
                                                {label:"Password",field:"password",type:'password'}
                                            ]
            
                                        })  
                                    }, 1000);
                                }
                            } else {
                            this.MBS.actions.go('/recover-password') 
                            }
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    })
                }
            }, 
            ON_QR_SCAN(code){
                try {  
                    this.SCANNING = true
                    this.dialog_scan_id = true
                    this.SCANNED_CODE = code 
                    this.SCANNED_NATION_ID = null 
                    setTimeout(() => {
                        this.SCANNING = false  
                        this.SCANNED_NATION_ID = this.MBS.actions.DECRYPT_ID_QR(code)  
                    }, 1000);
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_QR_SCAN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            FEEDBACKS(time=1000,event){
                try { 
                    setTimeout(()=>{
                        let us = this.us
                        let ud = this.ud
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses 
                        let i = this.iLocation 
                        
        
                        //get i and set country number 
                        if (i) {
                            this.select.country = i.country.code+ ': '+i.country.name+' ('+i.country.callingCode+')' 
                            this.select.countryNextOfKin = i.country.code+ ': '+i.country.name+' ('+i.country.callingCode+')'  
                        }else{
                            //this.$store.dispatch('setILocation')
                        }  
                        
                        //Check process 
                        if (ps) {
                            let logging = ps.find(process=>{
                                return process.name == 'logging'
                            }) 
        
                            if (logging) {
                                this.PAGE_LOAD =false
                            }else{
                                this.VIEW_PAGE()
                            }     
                        }else{
                            this.VIEW_PAGE()
                        }    
                    },time) 
                } catch (error) {
                    
                }
            },  
            VIEW_PAGE(){
                try {
                    let us = this.us
                    let ud = this.ud
                    let status = ud?ud.signup_status:null

                    //CHECK USER STATE
                    if (!us) {
                        this.PAGE_LOAD = true 
                    }else{
                        this.PAGE_LOAD =true  
                    }

                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    })
                }
            }, 
        },
        beforeDestroy(){
            this.MBS.events.$off('ON_QR_SCAN', this.ON_QR_SCAN); 
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
        },
        watch:{ 
            us(value){
                this.FEEDBACKS(1000)
            },
            processes(value){
                this.FEEDBACKS(1000)
            },
            loading(value){
                this.FEEDBACKS(1000)
            }, 
            iLocation(value){
                this.FEEDBACKS(1)
            }, 
        }
    }
</script>